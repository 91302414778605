body {
  background-color: #efeee9;
  scroll-behavior: smooth;
  font-family: 'Nunito Sans', sans-serif;
  color: #36454F;
  margin: 0;
  padding: 0;
}

header {
  background-color: #36454F;
  color: #efeee9;
  height: 70px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header a {
  color: #efeee9;
  text-decoration: none;
  margin: 10px
}

.sidebar {
  width: 17%;
  padding: 10px;
  background-color: #e1e0da;
  overflow-y: auto;
  height: calc(100vh - 90px);
  z-index: 10;
}

#get-started {
  background-color: #efeee9;
  color: #36454F;
  padding: 10px;
  font-weight: 500;
  border-radius: 12px;
  transition: all 0.2s;
}

#get-started:hover {
  background-color: #dbd9d2;
  color: #36454F;
}

#redirect-button {
  border: none;
  background-color: #2c3941;
  color: #efeee9;
  font-family: "Montserrat";
  padding: 10px;
  margin-top: 10px;
  font-weight: 200;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.2s;
}

#redirect-button:hover {
  background-color: #dbd9d2;
  color: #36454F;
}

.logo2 {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

#notetext {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-family: 'EB Garamond', serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

#notetext2 {
  text-align: center;
  padding-top: 100px;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'EB Garamond', serif;
  color: #efeee9;
  font-size: 54px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.hero {
  text-align: center;
  padding: 80px 20px;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.hero button {
  background-color: #36454F;
  color: #efeee9;
  border: none;
  padding: 15px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 18px;
}

.gsbtn {
  background-color: #36454F;
  color: #efeee9;
  border: none;
  padding: 15px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.features,
.how-it-works,
.testimonials {
  padding: 60px 20px 10px 20px;
  text-align: center;
}

.features h2,
.how-it-works h2,
.testimonials h2 {
  font-size: 3em;
  margin-bottom: 20px;
}
.features{
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.features h3{
  font-size: 1.4em;
}

.feature-item,
.step-item,
.testimonial-item {
  margin-bottom: 30px;
}

.feature-item{
  margin-left: 5px;
  margin-right: 5px;
}

.feature-item p{
  width: 300px;
}
.step-item p{
  width: 300px;
}

footer {
  background-color: #36454F;
  color: #efeee9;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #efeee9;
  text-decoration: none;
  margin: 0 10px;
}

.document-container {
  width: 100%;
  overflow: hidden;
}

.page {
  font-family: 'Times New Roman', Times, serif;
  font-size: 10px;
  line-height: 1.5;
  color: #333;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow: auto;
  break-inside: avoid;
  page-break-inside: avoid;
}

#fileName {
  width: 100%;
  text-align: left;
  border: none;
  background-color: #e1e0da;
  color: #191919;
  padding: 8px;
  border-radius: 10px;
  font-size: 15px;
  font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
  transition: all 0.1s;
}

#fileName:hover {
  background-color: #d7d6d1;
}

#fileName:active {
  background-color: #cdcbc5;
}

.tilted-parallelogram {
  width: 30px;
  height: 20px;
  background-image: linear-gradient(to top left, #cea3ff, #affff6, #ce92ff);
  border-radius: 4px;
  transform: skew(-10deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: bold;
}

.tilted-parallelogramnp {
  width: 70px;
  height: 20px;
  background-image: linear-gradient(to top left, #cea3ff, #affff6, #ce92ff);
  border-radius: 4px;
  transform: skew(-10deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #36454F;
  font-weight: bold;
}

#closefilecontent {
  text-align: right;
  border: none;
  background-color: #e1e0da;
  color: #36454F;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

#opside {
  margin-top: 10px;
  margin-left: 10px;
  align-self: flex-start;
  border: none;
  background-color: #efeee9;
  color: #36454F;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.main-content {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  scroll-behavior: smooth;
  height: calc(100vh - 85px);
}

.form-container {
  position: absolute;
  align-self: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 60px;
  z-index: 1;
}

.chat-section {
  flex-grow: 1;
  width: 70%;
  align-self: center;
}

#askinp {
  padding: 16px;
  width: 700px;
  border: none;
  border-radius: 25px;
  background-color: #e1e0da;
  font-size: 16px;
  outline: none;
  font-family: 'Nunito Sans', sans-serif;
}


.input-container {
  position: relative;
  display: inline-block;
}

#sendbtn {
  width: 40px;
  height: 40px;
  margin-top: 3px;
}

.send-button {
  position: absolute;
  right: 8px;
  /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#subbtn {
  border: none;
  vertical-align: bottom;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.inbtn {
  background-color: #425460;
  color: #efeee9;
  cursor: pointer;
  border-radius: 10px;
  padding: 4px 12px;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Nunito Sans', sans-serif;
  transition: 0.2s;
}

.inbtn:hover {
  color: #36454F;
  background-color: #c1c0c0;
}

.subscription-container {
  padding: 50px 30px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
}

.subscription-container h1 {
  margin-bottom: 40px;
}

.plan-selection {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.plan-option {
  padding: 30px;
  border: 2px solid #36454f00;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.plan-option:hover {
  transform: translateY(-5px);
}

.plan-option.selected {
  border: 2px solid #36454F;
}

.plan-option h2 {
  margin-bottom: 10px;
}

.subscribe-button {
  background-color: #36454F;
  color: #efeee9;
  padding: 15px 30px;
  font-size: 16px;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 40px;
  transition: all 0.2s;
}

.subscribe-button:hover {
  background-color: #485864;
}

.section {
  background: #efeee9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 250px;
}

.section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.section p {
  font-size: 1em;
  color: #666;
}

.app {
  text-align: center;
  background-color: #36454F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.h1n {
  font-size: 2.4em;
  font-family: "Montserrat";
  margin-bottom: 40px;
  color: #efeee9;
}

.sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}



@media (max-width: 1000px) {
  #askinp {
    width: 400px;
  }
}

@media (max-width: 600px) {
  #askinp {
    width: 200px;
  }
}

@media (max-width: 450px) {
  header a {
    font-size: 12px;
  }
  #notetext{
    font-size: 12px;
  }
  nav{
    justify-content: space-around;
  }
}

@media (max-width: 600px) {
  .features h2{
    font-size: 1.2em;
  }
  .feature-item h3{
    font-size: 1em;
  }
  .how-it-works h2{
    font-size: 1.2em;
  }
  .how-it-works h3{
    font-size: 1em;
  }
  .hero h1{
    font-size: 1.8em;
  }
  .hero p{
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 1em;
    width: 200px;
  }
}

@media (max-width: 450px) {
  .feature-item p{
    width: 100px;
  }
  .step-item p{
    width: 100px;
  }
}